import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const About = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="tokyo_tm_about">
        <div className="about_image ">
          <img src="assets/img/slider/whoami.jpg" alt="about" />
        </div>
        {/* <div className="o-video">
          <iframe src="https://www.youtube.com/embed/0yW7w8F2TVA"></iframe>
        </div> */}
        {/* END ABOUT IMAGE */}
        <div className="description">
          <h3 className="name">About me</h3>
          <div className="description_inner">
            <div className="left">
              <p>
              Hello, I'm Kevin. A Front-End and Back-End Software Developer based in Vancouver. I specialize in utilizing Microsoft's .NET infrastructure to create software solutions and developing regression test scripts using Selenium Automation Framework. I am also an iOS developer responsible for the development and maintenance of applications aimed at a range of iOS devices.
              </p>
              <div className="tokyo_tm_button">
                <button onClick={toggleModal} className="ib-button">
                  Read More
                </button>
              </div>
              {/* END TOKYO BUTTON */}
            </div>
            {/* END LEFT */}
            <div className="right">
              <ul>
                <li>
                  <p>
                    <span>Email:</span>
                    <a href="mailto:contact@kevwu.com">contact@kevwu.com</a>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Freelance:</span>Available
                  </p>
                </li>
              </ul>
              {/* END UL */}
            </div>
            {/* END RIGHT */}
          </div>
          {/* END DESCRIPTION INNER */}
        </div>
      </div>

      {/* START ABOUT POPUP BOX */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_about">
          <button className="close-modal" onClick={toggleModal}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END POPUP CLOSE BUTTON */}
          <div className="box-inner">
            <div
              className="description_wrap scrollable"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="counter">
                  <div className="about_title">
                    <h3>PROFESSIONAL SUMMARY</h3>
                  </div>
                  {/* END ABOUT TITLE */}
                    <p>Results-driven, accomplished, multilingual developer highly regarded for developing applications that meet and exceed demanding corporate and client requirements. Sought for superb analytical skills, with the ability to solve challenging problems using a combination of industry standard and cutting-edge technologies, delivering clean, well-structured code. Skillset to work compatibly with clients to understand requirements and develop products that exceed quality expectations with on-time delivery. Strong team leadership skills and ability to collaborate with individuals from diverse backgrounds.</p>
              </div>
              {/* END COUNTER */}

              <div className="blocks">
                <div className="about_title">
                  <h3>CORE COMPETENCIES</h3>
                </div>
                <ul>
                  <li>
                    <div className="list_inner">
                      <h3>Process Improvements</h3>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <h3>Automation Initiatives</h3>
                    </div>
                  </li>
                  <li>
                    <div className="list_inner">
                      <h3>Software Development</h3>
                    </div>
                  </li>
                </ul>
                {/* END COUNTER CONTENT */}
              </div>
              {/* END COUNTER */}

              <div className="counter">
                  <div className="about_title">
                    <h3>TECHNICAL SKILLS</h3>
                  </div>
                  {/* END ABOUT TITLE */}
                    <p><b>Programming Languages:</b> C#, C++, C, Python, Perl, Objective-C, Swift, PowerShell, Batch, VBScript, AutoIt</p>
                    <p><b>Web Design:</b> HTML, CSS3, JavaScript, PHP, React</p>
                    <p><b>Database:</b> MySQL, SQLite</p>
                    <p><b>Frameworks:</b> .NET Framework, Selenium</p>
                    <p><b>Operating Systems:</b> Windows, MacOS, Linux (Ubuntu, Oracle Linux, CentOS, Red Hat)</p>
                    <p><b>Version Control:</b> GIT</p>
                    <p><b>Editor:</b> Vi, Nano</p>
              </div>
              {/* END COUNTER */}

              <div className="counter">
                <div className="about_title">
                  <h3>TECHNICAL EXPERIENCE</h3>
                </div>
                <ul>
                <li>Engaged in technical project planning and coordination of hardware and/or software business solutions in a LAN/WAN Microsoft Windows environment</li>
                <li>Analyzed and resolved complex Microsoft Windows Operating Systems, Microsoft Office suite of products, and TCP/IP Network problems</li>
                <li>Advanced Microsoft Operating System configuration such as Group Policy, Active Directory, or creating images for enterprise deployment</li>
                <li>Customized PowerShell scripts to perform data sanitation, configuration and GPO policies.</li>
                <li>Experienced in Microsoft System Center Configuration Manager (SCCM)</li>
                <li>Utilized Active Directory (AD objects, LDAP queries, GPO, PowerShell scripting for AD)</li>
                <li>Windows Installer MSI technology (features, components, MSI database, parameters, editing, logging, and troubleshooting)</li>
                <li>Managed Operating System Deployment configuration (WIM files, Driver packages, Customized Task sequences, UEFI/BIOS and Registry configuration)</li>
                <li>Deployed, troubleshot, and managed Bitlocker encryption on Windows devices</li>
                <li>Enterprise Software Delivery Systems (SCCM), used Application and/or Packaging model, build, configure and deploy OS Images using Task Sequences, configure and deploy Applications and Security Updates, troubleshoot deployments, build custom queries and collections, troubleshoot and maintain SCCM client health)</li>
                <li>Garnered 10+ years of work experience with BC Health Authorities IT Environment</li>
                </ul>
              </div>
              {/* END COUNTER */}
            </div>
          </div>
        </div>
      </Modal>
      {/* END ABOUT POPUP BOX */}
    </>
  );
};

export default About;
