import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import ReactTooltip from "react-tooltip";
import ModalVideo from "react-modal-video";
import Modal from "react-modal";
import Social from "./Social";

const Portfolio = () => {
  // for popup video
  const [isOpen, setOpen] = useState(false);
  const [isOpen2, setOpen2] = useState(false);

  // for modal
  const [isOpenMiningbird, setIsOpenMiningbird] = useState(false);
  const [isOpenVaneat, setIsOpenVaneat] = useState(false);
  const [isOpenSupportTool, setIsOpenSupportTool] = useState(false);
  const [isOpenTerminalServerTool, setIsOpenTerminalServerTool] = useState(false);

  function toggleModalMiningbird() {
    setIsOpenMiningbird(!isOpenMiningbird);
  }
  function toggleModalVaneat() {
    setIsOpenVaneat(!isOpenVaneat);
  }
  function toggleModalSupportTool() {
    setIsOpenSupportTool(!isOpenSupportTool);
  }
  function toggleModalTerminalServerTool() {
    setIsOpenTerminalServerTool(!isOpenTerminalServerTool);
  }

  return (
    <>
      <SimpleReactLightbox>
        <div className="tokyo_tm_portfolio">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>Portfolio</span>
                <h3>Projects</h3>
              </div>
            </div>
          </div>
          {/* END TOKYO_TM_TITLE */}

          <div className="portfolio_filter">
            <Tabs>
              <TabList>
                <Tab>All</Tab>
                <Tab>iOS</Tab>
                <Tab>Windows</Tab>
              </TabList>
              {/* END TABLIST */}
              <div className="list_wrapper">
                <TabPanel>
                  <ul className="portfolio_list">
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="200"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/miningbird.jpg"
                            alt="Details"
                            data-tip
                            data-for="miningbird"
                            onClick={toggleModalMiningbird}
                          />

                          <ReactTooltip
                            id="miningbird"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Claymore Remote Monitor</h5>
                              <span>Real-time monitoring App for GPU Miner</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* END DETAILS */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="200"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/vaneat.jpg"
                            alt="Details"
                            data-tip
                            data-for="vaneat"
                            onClick={toggleModalVaneat}
                          />

                          <ReactTooltip
                            id="vaneat"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Vaneat</h5>
                              <span>Local Restaurant Directory</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* END DETAILS */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/supporttool.jpg"
                            alt="Details"
                            data-tip
                            data-for="supporttool"
                            onClick={toggleModalSupportTool}
                          />

                          <ReactTooltip
                            id="supporttool"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>SD Support Tool</h5>
                              <span>Remote Management Toolset</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* END DETAILS */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/terminalservertool.jpg"
                            alt="Details"
                            data-tip
                            data-for="terminalservertool"
                            onClick={toggleModalTerminalServerTool}
                          />

                          <ReactTooltip
                            id="terminalservertool"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Terminal Server Tool</h5>
                              <span>Terminal Server Session Management</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* END DETAILS */}
                  </ul>
                  {/* END PORTFOLIO LIST */}
                </TabPanel>
                {/* END ALL PORTFOLIO GALLERY */}

                <TabPanel>
                  <ul className="portfolio_list">
                    <li data-aos="fade-right" data-aos-duration="1200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/miningbird.jpg"
                            alt="Details"
                            data-tip
                            data-for="detail"
                            onClick={toggleModalMiningbird}
                          />

                          <ReactTooltip
                            id="detail"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Claymore Remote Monitor</h5>
                              <span>Real-time monitoring App for GPU Miner</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* END DETAILS */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/vaneat.jpg"
                            alt="Details"
                            data-tip
                            data-for="detail2"
                            onClick={toggleModalVaneat}
                          />

                          <ReactTooltip
                            id="detail2"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Vaneat</h5>
                              <span>Local Restaurant Directory</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* END DETAILS */}
                  </ul>
                  {/* END DETAILS GALLERY */}
                </TabPanel>
                {/* END iOS */}

                <TabPanel>
                  <ul className="portfolio_list">
                    <li data-aos="fade-right" data-aos-duration="1200">
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/supporttool.jpg"
                            alt="Details"
                            data-tip
                            data-for="supporttool"
                            onClick={toggleModalSupportTool}
                          />

                          <ReactTooltip
                            id="supporttool"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>SD Support Tool</h5>
                              <span>Remote Management Toolset</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* END DETAILS */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/terminalservertool.jpg"
                            alt="Details"
                            data-tip
                            data-for="terminalservertool"
                            onClick={toggleModalTerminalServerTool}
                          />

                          <ReactTooltip
                            id="terminalservertool"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Terminal Server Tool</h5>
                              <span>Terminal Server Session Management</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* END DETAILS */}
                  </ul>
                  {/* END DETAILS GALLERY */}
                </TabPanel>
                {/* END Windows */}
              </div>
              {/* END LIST WRAPPER */}
            </Tabs>
          </div>
        </div>
      </SimpleReactLightbox>

      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenMiningbird}
        onRequestClose={toggleModalMiningbird}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalMiningbird}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/miningbird.jpg)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Claymore Remote Monitor</h3>
                <span>Real-time monitoring App for Cryptocurrency GPU Miner</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  Claymore Remote Monitor is a real-time monitoring App for Cryptocurrency miners that supports
                  multiple GPU mining software. Users can monitor their Cryptocurrency mining rigs locally or
                  remotely from their iOS mobile device or tablet at ease.
                  </p>
                  <h6>Features</h6>
                  <ul>
                  <li>Shows total and individual miner hashrate, accepted shares, rejected shares, invalid shares, and pool switches.</li>
                  <li>Shows individual GPU hashrate, temperature and fan speed.</li>
                  <li>Supports Claymore dual mining stats (ETH, DCR, SIA, XVG, SMART, MAX, LBC, PASC).</li>
                  <li>Ability to set auto refresh time.</li>
                  <li>Ability to access console mode.</li>
                  <li>Ability to restart miner.</li>
                  <li>Ability to call "reboot.bat" or "reboot.bash" (or "reboot.sh").</li>
                  <li>Supports Claymore TCP and HTTP connection.</li>
                  <li>Supports PhoenixMiner.</li>
                  <li>Supports Ethminer.</li>
                  <li>Supports SRBMiner.</li>
                  <li>Background Monitoring and Push Notification.</li>
                  </ul>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Category</span>
                      <span>iOS</span>
                    </li>
                    <li>
                      <span className="first">Date</span>
                      <span>January, 2018</span>
                    </li>
                    <li>
                      <span className="first">Website</span>
                      <span><a href="https://miningbird.com">miningbird.com</a></span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/miningbird_1.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/miningbird_2.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/miningbird_3.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}

      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenVaneat}
        onRequestClose={toggleModalVaneat}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalVaneat}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/vaneat.jpg)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Vaneat</h3>
                <span>Restaurant directory App for iOS</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>溫食 is a restaurant directory for iOS App targeting local restaurants, bars,
                    and venues in the greater Vancouver area. It uses Foursquare API to access global
                    POI data and rich content. Users can search, discover, rank venues and get real-time data.
                  </p>
                  <h6>Features</h6>
                  <ul>
                    <li>
                    豐富餐館資料 - 通過優化Foursquare API提供的豐富資料包括地址,聯繫電話,營業時間等等.
                    </li>
                    <li>
                      智能定位 - 結合使用手機的GPS和Wi-FI定位功能實現實時定位.
                    </li>
                    <li>
                    自定義搜索 - 使用地圖模式可以自定義搜索的範圍.
                    </li>
                    <li>
                    評論平台 - 使用強大的Disqus討論平台,可以隨時與大家共享您對餐館的感受.
                    </li>
                    <li>
                    分享給好友 - 您可以輕鬆的把餐館資料地址,聯繫電話通過E-mail,短訊分享給好友,或直接分享給Facebook和微信好友.
                    </li>
                    <li>
                    主題和語言 - 有兩個主題可以選擇,分別為番茄紅和黑色. 支持中文繁體和簡體。
                    </li>
                  </ul>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Category</span>
                      <span>iOS</span>
                    </li>
                    <li>
                      <span className="first">Date</span>
                      <span>March, 2015</span>
                    </li>
                    <li>
                      <span className="first">Website</span>
                      <span><a href="https://vaneat.ca">vaneat.ca</a></span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/vaneat_1.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/vaneat_2.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/vaneat_3.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}

      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenSupportTool}
        onRequestClose={toggleModalSupportTool}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalSupportTool}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/supporttool.jpg)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>SD Support Tool</h3>
                <span>Remote Management Toolset</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>The SD Support Tool is a .NET Windows Presentation Foundation (WPF) UI application written in C#, C++ and PowerShell,
                    which consolidates multiple tools into one tool. This toolset is designed to manage remote workstations by performing
                    asynchronous GRUD operations to Windows Management Instrumentation (WMI). It also utilizes the Lightweight
                    Directory Access Protocol (LDAP) classes for querying Active Directory Users, Groups, and Computer information.</p>
                    <h6>Features</h6>
                    <ul>
                      <li>Quick connect to remote workcation via Remote Assistance, Remote Desktop, PSExec, Powershell Console, MMC Snap-ins (Computer Management, Local Users and Groups, Shared Folders, Event Viewer and Administrative Shares).</li>
                      <li>Query workstation information (OS and Hardware, Logged-On Users, Network Adapters, Network Printers, Network Drives and AD Group Memberships).</li>
                      <li>Query AD User (AD User Properties, Exchange Mailbox Permissions, AD Group Memberships, Logon Script for Network Drive Mappings).</li>
                      <li>Query AD printer (AD printer properties, Access printer management webpage).</li>
                      <li>Manage User Profile (Query, Delete, Rename Local User Profiles).</li>
                      <li>Query Software Inventory (Installed Software ARP Registry Enteries, Hotfixes, Windows Update History, SCCM Applications).</li>
                      <li>Software Package Management (Install, Uninstall, Transfer, Install Status and Dependency Validation).</li>
                      <li>Validate Software Install Status against registry and install logs.</li>
                      <li>Validate AD Baseline Group Assignments.</li>
                      <li>Manage Processes.</li>
                      <li>Manage Services.</li>
                      <li>View System and Application Event Logs.</li>
                      <li>File Replication Validation.</li>
                      <li>Disk Space Cleanup.</li>
                      <li>Add Remote Desktop User and Temporary Local Administrator.</li>
                      <li>Logoff or Send message to Logged-On-User session.</li>
                      <li>Compare AD Computer or User Groups.</li>
                      <li>View/Reset BitLocker Recovery Password/Key.</li>
                      <li>Force Restart Windows Remotely.</li>
                      <li>Enable WinRM Remotely.</li>
                      <li>Reset WMI repository Remotely.</li>
                      <li>Force Group Policy Update Remotely.</li>
                      <li>Trigger Configuration Manager Client Actions.</li>
                    </ul>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Category</span>
                      <span>Windows</span>
                    </li>
                    <li>
                      <span className="first">Date</span>
                      <span>September, 2020</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}
              <div className="additional_images_normal">
                <ul className="gallery_zoom">
                  <li><img src="assets/img/portfolio/supporttool_1.jpg" /></li>
                  <li><img src="assets/img/portfolio/supporttool_2.jpg" /></li>
                  <li><img src="assets/img/portfolio/supporttool_3.jpg" /></li>
                  <li><img src="assets/img/portfolio/supporttool_4.jpg" /></li>
                  <li><img src="assets/img/portfolio/supporttool_5.jpg" /></li>
                  <li><img src="assets/img/portfolio/supporttool_6.jpg" /></li>
                  <li><img src="assets/img/portfolio/supporttool_7.jpg" /></li>
                  <li><img src="assets/img/portfolio/supporttool_8.jpg" /></li>
                  <li><img src="assets/img/portfolio/supporttool_9.jpg" /></li>
                  <li><img src="assets/img/portfolio/supporttool_10.jpg" /></li>
                  <li><img src="assets/img/portfolio/supporttool_11.jpg" /></li>
                  <li><img src="assets/img/portfolio/supporttool_12.jpg" /></li>
              </ul>
            </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}

      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenTerminalServerTool}
        onRequestClose={toggleModalTerminalServerTool}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalTerminalServerTool}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/terminalservertool.jpg)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Terminal Server Tool</h3>
                <span>Terminal Server Session Management</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  The Terminal Server Tool is a session management tool utilizes data parallelism
                  to perform session query operations concurrently, resulting
                  in blazing fast performance when locating active sessions from hundreds of RDS servers
                  in just a few seconds.
                  </p>
                  <h6>Features</h6>
                  <ul>
                    <li>
                    Search and logoff terminal server session.
                    </li>
                    <li>
                    Remote control terminal server session.
                    </li>
                  </ul>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Category</span>
                      <span>Windows</span>
                    </li>
                    <li>
                      <span className="first">Date</span>
                      <span>May, 2021</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/terminalservertool_1.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
    </>
  );
};

export default Portfolio;
